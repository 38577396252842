import React, { useState } from "react"
import Logo from "../images/logo-small.png"
import HamburgerMenu from "react-hamburger-menu"
import { navigate } from "gatsby"
import { NavLink } from "../utils"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleMenuClick = () => setIsOpen(!isOpen)
  const handleHomeClick = () => navigate("/")

  return (
    <>
      <div className="ew-header row mb-4">
        {/* brand */}
        <div
          className="col d-flex align-items-center"
          onClick={handleHomeClick}
        >
          <img className="ew-logo" src={Logo} alt="erethworks" />
          <span className="ew-brand ml-4">ereth works</span>
        </div>

        {/* nav */}
        <div
          role="navigation"
          className="col-2 col-md-4 d-flex justify-content-end align-items-center"
        >
          <div className="ew-nav">
            <span className="mr-4">
              <NavLink to="/about">About</NavLink>
            </span>
            <NavLink to="/contact">Contact</NavLink>
          </div>

          <div className="ew-menu">
            <HamburgerMenu
              isOpen={isOpen}
              menuClicked={handleMenuClick}
              rotate={0}
              width={18}
              height={15}
              color="black"
              strokeWidth={1}
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
        </div>
      </div>

      {/* mobile nav */}
      {isOpen && (
        <div className="row ew-collapse-menu no-gutters text-center mb-2 py-2">
          <div className="col-4 border-right border-dark">
            <NavLink to="/">Home</NavLink>
          </div>
          <div className="col-4 border-right border-dark">
            <NavLink to="/about/">About</NavLink>
          </div>
          <div className="col-4">
            <NavLink to="/contact/">Contact</NavLink>
          </div>
        </div>
      )}
    </>
  )
}

export default Header
